import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Row,
    Col,
    FormGroup, Label, Input,
    Button
} from 'reactstrap';
import {requestData} from "core/ducks/list";
import Loading from "core/components/loading";
import {Bar} from 'react-chartjs-2';
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import {defaults as defaultControls} from "ol/control/util";
import View from "ol/View";
import Feature from "ol/Feature";
import PointGeom from "ol/geom/Point";
import {fromLonLat} from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Circle as CircleStyle, Fill, Style, Text} from "ol/style";

class StatisticsSection1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pollutantSelect: 'PM2.5',
            fromSelect: '',
            toSelect: '',
            loading: true,
            data: false,
            startLoading: false
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    fetchData() {
        let self = this;
        self.setState({
            loading: true,
            data: false,
            startLoading: true
        });
        this.props.dispatch(requestData('statsPropertyData', 'stats/property/' + self.state.pollutantSelect + '/start/' + self.state.fromSelect + '/end/' + self.state.toSelect + '/')).then(() => {
            const d = self.props.statsPropertyDataList.data;
            if (d) {


                let labels = [];
                let dataArray = [];
                let unit = '';
                d.forEach((item) => {
                    labels.push(item.description);
                    dataArray.push(item.value ? item.value.toFixed(2) : item.value);
                    unit = item.value ? item.unit : unit;
                });

                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: self.state.pollutantSelect + ' (' + unit + ')',
                            backgroundColor: 'rgba(106,161,223,0.8)',
                            borderColor: 'rgba(106,161,223,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(106,161,223,0.8)',
                            hoverBorderColor: 'rgba(106,161,223,1)',
                            data: dataArray
                        }
                    ]
                };

                self.setState({
                    loading: self.props.statsPropertyDataWait,
                    data: data
                });


                //set map
                let map = new Map({
                    layers: [
                        new TileLayer({
                            source: new OSM()
                        })
                    ],
                    controls: defaultControls({attribution: false}).extend([]),
                    target: 'statistics-section1-map',
                    view: new View({
                        center: [2568716.458, 4937330.641],
                        zoom: 12,
                        minZoom: 10,
                        extent: [2523768.436, 4922345.743, 2595466.368, 4955137.228]
                    })
                });

                let stationLayer = this.createLayer(d);
                map.addLayer(stationLayer);
            }
        });

    }

    createLayer(stations) {
        let features = [];
        stations.forEach(function (station) {
            let feature = new Feature({
                geometry: new PointGeom(fromLonLat([Number(station.location.lon), Number(station.location.lat)])),
                name: station.description,
                token: station.token,
                value: station.value ? station.value.toFixed(2) : station.value,
                unit: station.unit ? station.unit : null,
            });
            features.push(feature);
        })
        let vectorSource = new VectorSource({features: features});

        let setStyle = (feature) => {

            let s = feature.get('value') ? new Fill({color: [106, 161, 223, 1]}) : new Fill({color: [160, 160, 160, 0.7]});
            return new Style({
                image: new CircleStyle({
                    fill: s,
                    radius: 12
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '14px Calibri,sans-serif',
                    text: feature.get('value') ? Number(feature.get('value')).toFixed(0).toString() : '',
                    fill: new Fill({color: '#000'}),
                    placement: 'point',
                    overflow: 'true'
                })
            });
        };

        return new VectorLayer({
            source: vectorSource,
            style: setStyle,
            baseLayer: true,
            visible: true
        });
    }

    render() {

        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="pollutantSelect">Ρύπος</Label>
                            <Input type="select" name="pollutantSelect"
                                   id="pollutantSelect" onChange={(e) => {
                                this.setState({pollutantSelect: e.target.value})
                            }}>
                                <option>PM2.5</option>
                                <option>PM1.0</option>
                                <option>PM10</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="fromSelect">Από Ημερομηνία</Label>
                            <Input
                                type="date"
                                name="fromSelect"
                                id="fromSelect"
                                placeholder="date placeholder"
                                onChange={(e) => {
                                    this.setState({fromSelect: e.target.value})
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="toSelect">Έως Ημερομηνία</Label>
                            <Input
                                type="date"
                                name="toSelect"
                                id="toSelect"
                                placeholder="date placeholder"
                                onChange={(e) => {
                                    this.setState({toSelect: e.target.value})
                                }}
                                locale={this.state.locale}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button color="primary" style={{marginTop: '2em', width: '100%'}}
                                onClick={this.fetchData}>Εμφάνιση</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr/>
                        {this.state.loading ?
                            this.state.startLoading ? <div style={{height: '100px', width: '100%'}}><Loading/></div> : ''
                            :
                            <div>
                                <div style={{height: '500px', width: '100%'}}>
                                    <h5>Μέση Τιμή Συγκέντρωσης ανά Σταθμό</h5>
                                    <Bar
                                        data={this.state.data}
                                        width={100}
                                        height={50}
                                        options={{
                                            maintainAspectRatio: false
                                        }}
                                    />
                                </div>
                                <hr/>
                                <div id="statistics-section1-map" style={{height: 'calc(100% - 9em)'}}/>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    statsPropertyDataList: state.list.statsPropertyData.data,
    statsPropertyDataWait: state.list.statsPropertyData.pending,
});

StatisticsSection1 = connect(mapStateToProps)(StatisticsSection1);

export default StatisticsSection1;
