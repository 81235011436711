import React, {Component} from 'react';
import {connect} from 'react-redux';


class TermsOfUse extends Component {

    render() {

        return (
            <div>
                <p>{this.props.i18n['thermiairTermsOfUse_p1']}</p>
                <p>{this.props.i18n['thermiairTermsOfUse_p2']}</p>
                <p>{this.props.i18n['thermiairTermsOfUse_p3']}</p>
                <p>{this.props.i18n['thermiairTermsOfUse_p4']}</p>
                <p>{this.props.i18n['thermiairTermsOfUse_p5']}</p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

TermsOfUse = connect(mapStateToProps)(TermsOfUse);

export default TermsOfUse;
